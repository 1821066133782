import React, {Component, Fragment} from 'react';
import {withErrorHandler} from '../util/errorHandler';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {defaultStyles} from '../util/styles';
import {
  List as LogIcon,
  Restore as RestoreIcon,
  Add as AddIcon,
  Pause as PauseIcon,
  PlayArrow as ResumeIcon,
  MoreVert as MoreVertIcon,
  Clear as ClearIcon,
  InfoOutlined as InfoIcon,
  Block as KillIcon, ExpandLess, ExpandMore,
  AssessmentOutlined as AssessmentIcon,
  Build as RedeployIcon
} from '@material-ui/icons';
import LooksRareIcon from '../res/img/looksrare.svg';
import OpenSeaIcon from '../res/img/opensea.svg';
import strings from '../strings';
import {cleanContainerName, downloadLocalFile, dtFormat} from '../util/helpers';
import ParamsAutocomplete from '../components/ParamsAutocomplete';
import ParametersEditor from '../components/ParametersEditor';
import AccountsSelect from '../components/AccountsSelect';
import InfoComponent from '../components/InfoComponent';
import {getStatusText, getStatusColor} from '../util/helpers';
import {
  withStyles,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Box, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem, ListItemIcon, Card,
} from '@material-ui/core';
import LogDialog from '../components/LogDialog';
import api from '../util/api';
import {amber, green, grey, red} from "@material-ui/core/colors";
import clsx from "clsx";

const styles = theme => ({
  ...defaultStyles(theme),
  session: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 6,
    position: 'relative',
    borderColor: grey[300],
    overflow: 'hidden',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  tags: {
    color: grey[600],
    fontWeight: 300,
    paddingLeft: 1,
  },
  savingOverlay: {
    position: 'absolute',
    borderRadius: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(224, 224, 224, 0.8)',
  },
  dot: {
    borderRadius: '50%',
    width: 6,
    height: 6,
  },
  pid: {
    color: grey[600],
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 1,
    marginTop: 4,
  },
  name: {
    color: grey[800],
    fontWeight: 500,
  },
  listItemIcon: {
    minWidth: 36,
  },
});

class InstanceWithSessionData extends Component {
  state = {
    editableParams: null,
    paramsValid: true,
    editableSelectedAccounts: null,
    logDialogOpen: false,
    saving: false,
    expanded: false,
    tooltipOpen: false,
    anchor: null,
    infoDialogOpen: false,
    stopDialogOpen: false,
    killDialogOpen: false,
    redeployWorkersDialogOpen: false,
    redeployWorkersErrors: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.editableParams) return {
      editableParams: props.instance.session.params,
      paramsValid: true,
      editableSelectedAccounts: props.instance.session.selected_accounts,
    };
    else return state;
  }

  openLogDialog = () => this.setState({
    logDialogOpen: true,
  });

  redeployWorkers = (instance) => {
    this.setState({
      redeployWorkersDialogOpen: true,
      redeployWorkersErrors: null,
    })

    api.post('workers/redeploy', {
      bot_uid: instance.uuid,
      image_url: instance.script,
    }, {
      onSuccess: response => this.setState({redeployWorkersErrors: response.data}),
      onError: reason => this.props.handleApiError(reason, this.props.history),
    })
  }

  closeLogDialog = () => this.setState({
    logDialogOpen: false,
  });

  updateSelectedAccounts = newAccounts => this.setState({
    editableSelectedAccounts: newAccounts,
  });


  openStopDialog = () => this.setState({stopDialogOpen: true});
  closeStopDialog = () => this.setState({stopDialogOpen: false});
  openKillDialog = () => this.setState({killDialogOpen: true});
  closeKillDialog = () => this.setState({killDialogOpen: false});
  stopInstance = () => {
    this.closeStopDialog();
    api.get(`instances/stop/${this.props.instance.id}`, {}, {
      onSuccess: response => {
        this.props.showSnackbar(strings.instanceStopped);
        this.props.onSave();
      },
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };


  killInstance = () => {
    this.closeKillDialog();
    api.get(`instances/${this.props.instance.id}/kill`, {}, {
      onSuccess: response => {
        this.props.showSnackbar(strings.instanceKilled);
        this.props.onSave();
      },
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };

  openInfoDialog = () => this.setState({
    infoDialogOpen: true,
  });
  closeInfoDialog = () => this.setState({
    infoDialogOpen: false,
  });

  closeMenu = () => this.setState({
    anchor: null,
  });
  openMenu = event => this.setState({
    anchor: event.currentTarget,
  });

  onInfoClicked = () => {
    this.closeMenu();
    this.openInfoDialog();
  };

  onStopInstanceClicked = () => {
    this.closeMenu();
    this.openStopDialog();
  };

  onKillInstanceClicked = () => {
    this.closeMenu();
    this.openKillDialog();
  };

  downloadLog = () => {
    const id = this.props.instance.id;
    api.get(`instances/${id}/log_download`, {}, {
      onSuccess: response => downloadLocalFile(`${id}.log`, response.data),
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };

  getMenu = () => {
    const {classes} = this.props;
    return <Menu
      anchorEl={this.state.anchor}
      keepMounted
      open={Boolean(this.state.anchor)}
      onClose={this.closeMenu}>
      <MenuItem onClick={this.onInfoClicked}>
        <ListItemIcon className={classes.listItemIcon}>
          <InfoIcon fontSize="small"/>
        </ListItemIcon>
        {strings.viewInfo}
      </MenuItem>

      <MenuItem onClick={this.downloadLog}>
        <ListItemIcon className={classes.listItemIcon}>
          <LogIcon fontSize="small"/>
        </ListItemIcon>
        {strings.downloadLog}
      </MenuItem>

      <MenuItem onClick={this.onStopInstanceClicked}>
        <ListItemIcon className={classes.listItemIcon}>
          <ClearIcon fontSize="small"/>
        </ListItemIcon>
        {strings.stopInstance}
      </MenuItem>

      <MenuItem onClick={this.onKillInstanceClicked}>
        <ListItemIcon className={classes.listItemIcon}>
          <KillIcon fontSize="small"/>
        </ListItemIcon>
        {strings.forceKill}
      </MenuItem>
    </Menu>;
  };

  openTooltip = () => this.setState({tooltipOpen: true});
  closeTooltip = () => this.setState({tooltipOpen: false});

  getStatusBackground = () => {
    const {instance} = this.props;

    if (!instance.pid_alive || !instance.started_success) return red[100];
    if (!instance.should_run && instance.pid_alive) return red[100];

    if (!instance.session.params_acknowledged) return amber[100];
    if (!instance.should_run) return amber[100];

    if (instance.session.paused) return grey[200];

    return green[100];
  };

  getStopDialog = () => {
    return <Dialog open={true} onClose={this.closeStopDialog}>
      <DialogTitle>
        {strings.stopBotInstance}
      </DialogTitle>
      <DialogContent>
        <Box pb={3}>
          <Typography>
            {strings.stopBotDescription}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.closeStopDialog}>
          {strings.cancel}
        </Button>
        <Button variant='contained' disableElevation color='primary' onClick={this.stopInstance}>
          {strings.stopInstance}
        </Button>
      </DialogActions>
    </Dialog>;
  };

  getKillDialog = () => {
    return <Dialog open={true} onClose={this.closeKillDialog}>
      <DialogTitle>
        {strings.killBotInstance}
      </DialogTitle>
      <DialogContent>
        <Box pb={3}>
          <Typography>
            {strings.killBotDescription}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.closeKillDialog}>
          {strings.cancel}
        </Button>
        <Button variant='contained' disableElevation color='primary' onClick={this.killInstance}>
          {strings.forceKill}
        </Button>
      </DialogActions>
    </Dialog>;
  };

  getInfoDialog = () => {
    const {instance} = this.props;
    return <Dialog open={true} onClose={this.closeInfoDialog} fullWidth maxWidth='xs'>
      <DialogTitle style={{paddingRight: 8}}>
        <Grid container direction='row' justify='space-between' alignItems='center' wrap='nowrap' spacing={2}>
          <Grid item>
            {strings.instanceInfoTitle}
          </Grid>
          <Grid item>
            <IconButton onClick={this.closeInfoDialog}>
              <ClearIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12}>
            <InfoComponent name={strings.containerId}
                           content={`${instance?.container_id?.substring(0, 10)} (${instance.pid_alive ? strings.running : strings.notRunning})`}
                           textToCopy={instance?.container_id ? instance.container_id.substring(0, 10) : ''}/>
          </Grid>
          <Grid item xs={12}>
            <InfoComponent name={strings.uuid} content={instance.uuid}/>
          </Grid>
          <Grid item xs={12}>
            <InfoComponent name={strings.port} content={instance?.port ?? '/'}/>
          </Grid>
          <Grid item xs={12}>
            <InfoComponent disableCopy name={strings.created}
                           content={moment(instance.created).format(dtFormat)}/>
          </Grid>
          <Grid item xs={12}>
            <InfoComponent name={strings.tag} content={instance.script}/>
          </Grid>
          <Grid item xs={12}>
            <InfoComponent name={strings.sessionUuid} content={instance.session.uuid}/>
          </Grid>
          <Grid item xs={12}>
            <InfoComponent disableCopy name={strings.sessionParamsAcknowledged}
                           content={instance.session.params_acknowledged ? strings.true : strings.false}/>
          </Grid>
          <Grid item xs={12}/>
          <Grid item xs={12}/>
        </Grid>
      </DialogContent>
    </Dialog>;
  };

  getInfo = () => {
    const {instance} = this.props;

    return <Grid container direction='row' spacing={2} justify='center'>
      <Grid item xs={12} md={6} lg={3}>
        <InfoComponent name={strings.startedAt} disableCopy
                       content={moment(instance.session.started_time).format(dtFormat)}/>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <InfoComponent name={strings.paused} disableCopy content={instance.session.paused.toString()}/>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <InfoComponent name={strings.acknowledged} disableCopy
                       content={instance.session.params_acknowledged.toString()}/>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <InfoComponent name={strings.status} disableCopy
                       contentColor={getStatusColor(instance)}
                       content={getStatusText(instance)}/>
      </Grid>

      <Grid item xs={12}/>
    </Grid>;
  };

  onParamsChanged = event => {
    if (event.error) this.setState({paramsValid: false});

    else this.setState({
      editableParams: event.value,
      paramsValid: true,
    });
  };

  onParamsSelected = params => this.setState({
    editableParams: params,
  });

  getParams = () => {
    const {editableParams} = this.state;
    return <Fragment>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          {strings.parameters}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ParamsAutocomplete onOptionSelected={this.onParamsSelected}/>
      </Grid>
      <Grid item xs={12}>
        <ParametersEditor placeHolder={editableParams} onChange={this.onParamsChanged}/>
      </Grid>
    </Fragment>;
  };

  anythingChanged = () => {
    const {instance} = this.props;
    const {editableParams, editableSelectedAccounts} = this.state;

    if (JSON.stringify(instance.session.params) !== JSON.stringify(editableParams)) return true;
    if (editableSelectedAccounts.sort().join(',') !== instance.session.selected_accounts.sort().join(',')) return true;
    return false;
  };

  resetValues = () => {
    this.setState({
      editableParams: this.props.instance.session.params,
      paramsValid: true,
      editableSelectedAccounts: this.props.instance.session.selected_accounts,
    });
  };

  saveNewSession = pause => {
    if (!this.state.paramsValid) return;
    this.setState({
      saving: true,
    });
    api.post(`sessions/new/${this.props.instance.id}`, {
        pause: pause,
        params: this.state.editableParams,
        selected_accounts: this.state.editableSelectedAccounts,
      },
      {
        onSuccess: () => {
          this.props.onSave();
          this.setState({saving: false});
        },
        onError: reason => this.props.handleApiError(reason, this.props.history),
      });
  };

  getActionsRow = () => {
    const {classes, instance} = this.props;
    return <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
      {
        instance.script.includes('opensea-nft-queue') || instance.script.includes('opensea-trait-queue')  ? <Grid item xs={12} sm='auto'>
          <Button color='secondary' variant='contained' disableElevation
                  href={
                    instance.port === 4444 ?
                      `https://queue.bots.zerodays.dev/?uid=${instance.uuid}` :
                      (instance.port === 6666 ? `https://clicker-queue.bots.zerodays.dev/?uid=${instance.uuid}` : `https://lueue.bots.zerodays.dev/?uid=${instance.uuid}`)
                  } rel="noopener noreferrer"
                  target="_blank"
                  className={classes.w100} startIcon={<AssessmentIcon/>}>
            {strings.dashboard}
          </Button>
        </Grid> : null
      }
      {
        instance.script.includes('nft-trader') || instance.script.includes('looksrare-trader') ?
          <Grid item xs={12} sm='auto'>
            <Button color='secondary' variant='contained' disableElevation
                    onClick={() => this.redeployWorkers(instance)}
                    className={classes.w100} startIcon={<RedeployIcon/>}>
              {strings.redeployWorkers}
            </Button>
          </Grid> : null
      }

      <Grid item xs={12} sm='auto'>
        <Button color='secondary' variant='contained' disableElevation onClick={this.openLogDialog}
                className={classes.w100} startIcon={<LogIcon/>}>
          {strings.viewLog}
        </Button>
      </Grid>

      <Grid item xs={12} sm='auto'>
        <Button color='secondary' variant='contained' disableElevation
                disabled={!this.anythingChanged() && this.state.paramsValid} className={classes.w100}
                onClick={this.resetValues} startIcon={<RestoreIcon/>}>
          {strings.resetValues}
        </Button>
      </Grid>

      <Grid item xs={12} sm='auto'>
        <Button color='primary' variant='contained' disableElevation
                disabled={!this.anythingChanged() || !this.state.paramsValid}
                onClick={() => this.saveNewSession(instance.session.paused)}
                className={classes.w100} startIcon={<AddIcon/>}>
          {strings.saveAsNewSession}
        </Button>
      </Grid>

      <Grid item xs={12} sm='auto'>
        <Button color='primary' variant='contained' disableElevation className={classes.w100}
                onClick={() => this.saveNewSession(!instance.session.paused)}
                disabled={!this.state.paramsValid}
                startIcon={
                  instance.session.paused ? <ResumeIcon/> : <PauseIcon/>
                }>
          {
            instance.session.paused ? strings.saveResume : strings.savePause
          }
        </Button>
      </Grid>

    </Grid>;
  };

  getSavingOverlay = () => {
    const {classes} = this.props;
    return <Grid container direction='row' justify='center' alignItems='center' className={classes.savingOverlay}
                 spacing={3}>
      <Grid item>
        <CircularProgress/>
      </Grid>
    </Grid>;
  };
  toggleExpanded = () => this.setState(prevState => ({
    ...prevState,
    expanded: !prevState.expanded,
  }))

  getHeader = () => {
    const {instance, classes} = this.props;
    const {expanded} = this.state;
    let tagName = instance.script;
    let tags = '';

    if (tagName.includes(':')) {
      tagName = instance.script.substring(0, instance.script.indexOf(':'));
      tags = instance.script.substring(instance.script.indexOf(':') + 1, instance.script.length);
    }

    let queueImageUrl = null;
    if (instance.port === 4444) {
      queueImageUrl = OpenSeaIcon;
    } else if (instance.port === 6969) {
      queueImageUrl = LooksRareIcon;
    }

    return <Box p={1} pl={2}>
      <Grid container direction='row' alignItems='center' spacing={1} wrap='nowrap'>

        <Grid item className={clsx(classes.cursorPointer, classes.flexGrow)} onClick={this.toggleExpanded}>
          <Grid container direction='row' alignItems='center' spacing={1} wrap='nowrap'>

            <Grid item>
              <Box pt={1}>
                {expanded ? <ExpandLess/> : <ExpandMore/>}
              </Box>
            </Grid>

            <Grid item>
              <Typography className={classes.name}>
                {cleanContainerName(tagName)}{
                tags.length > 0 ? <span className={classes.tags}>:{tags}</span> : null
              }
              </Typography>
            </Grid>
            {
              queueImageUrl != null ? <Grid item>
                <Box pt={0.5} ml={1}>
                  <img src={queueImageUrl} height={22} alt={"logo"}/>
                </Box>
              </Grid> : null
            }
          </Grid>
        </Grid>

        <Grid item>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={this.openMenu}
            size='small'
          >
            <MoreVertIcon/>
          </IconButton>
        </Grid>
      </Grid>
    </Box>;
  }

  getRedeployDialog = () => {
    const {redeployWorkersDialogOpen, redeployWorkersErrors} = this.state;

    return <Dialog open={redeployWorkersDialogOpen} fullWidth maxWidth='sm'>
      {
        redeployWorkersErrors == null ? <Grid container justifyContent='center'>
            <Grid item>
              <Box p={3}>
                <CircularProgress/>
              </Box>
            </Grid>
          </Grid> :
          (
            <Grid container justifyContent='flex-end'>
              <Grid item xs={12}>
                <Box p={3}>
                  {
                    redeployWorkersErrors.filter(item => !item.start_successful).length === 0 ?

                      <Typography align='center'>{strings.redeploySuccess}</Typography> :
                      <>
                        <Typography align='center'>{strings.redeployErrors}</Typography>
                        <ul>
                          {redeployWorkersErrors.filter(item => !item.start_successful).map((item, i) => (
                            <li key={i.toString()}>{item.name} ({item.address})</li>
                          ))
                          }
                        </ul>
                      </>
                  }
                </Box>
              </Grid>
              <DialogActions item xs={12} sm='auto'>
                <Button onClick={() => this.setState({
                  redeployWorkersDialogOpen: false,
                  redeployWorkersErrors: null,
                })}>{strings.close}</Button>
              </DialogActions>
            </Grid>
          )
      }
    </Dialog>
  }

  render() {
    const {instance} = this.props;
    const {editableSelectedAccounts, expanded} = this.state;

    return <Tooltip title={getStatusText(instance)} placement='top' open={this.state.tooltipOpen}
                    onClose={this.closeTooltip} onOpen={this.openTooltip}>
      <Card elevation={0}>
        <Grid container direction='row' alignItems='center'>

          <Grid item xs={12}
                style={{borderColor: getStatusColor(instance), backgroundColor: this.getStatusBackground()}}>

            {this.getHeader()}
          </Grid>

          {expanded ? <Grid item xs={12}>
              <Grid container direction='row'>
                <Grid item xs={12}>
                  <Box pt={3} px={3}>
                    {this.getInfo()}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box p={3}>
                    <Typography variant='subtitle1'>
                      {strings.accounts}
                    </Typography>
                    <AccountsSelect value={editableSelectedAccounts}
                                    accounts={instance.session.accounts}
                                    onChange={this.updateSelectedAccounts}/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box p={3}>
                    {this.getParams()}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={4} px={2} pb={2}>
                    {this.getActionsRow()}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            : null}
        </Grid>

        <LogDialog log={instance.session.log} onClose={this.closeLogDialog} open={this.state.logDialogOpen}/>
        {
          this.state.saving ? this.getSavingOverlay() : null
        }

        {this.getMenu()}
        {this.state.infoDialogOpen ? this.getInfoDialog() : null}
        {this.state.stopDialogOpen ? this.getStopDialog() : null}
        {this.state.killDialogOpen ? this.getKillDialog() : null}
        {this.getRedeployDialog()}
      </Card>
    </Tooltip>;
  }
}

InstanceWithSessionData.propTypes = {
  instance: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withErrorHandler(withRouter(withStyles(styles)(InstanceWithSessionData)));