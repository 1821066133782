import React, { Component } from 'react';
import { defaultStyles } from '../util/styles';
import { grey } from '@material-ui/core/colors';
import strings from '../strings';
import PropTypes from 'prop-types';
import {
  KeyboardArrowDown,
} from '@material-ui/icons';
import { Clear as ClearIcon } from '@material-ui/icons';
import {
  withStyles,
  Dialog,
  DialogTitle,
  Button,
  Grid,
  DialogContent,
  Divider,
  Typography, IconButton,
} from '@material-ui/core';

const styles = theme => ({
  ...defaultStyles(theme),
  logLine: {
    fontSize: 15,
    fontWeight: 400,
    color: grey[700],
  },
  root: {
    position: 'relative',
  },
  scrollButton: {
    position: 'absolute',
    bottom: 12,
    right: 24,
    transition: 'opacity 200ms ease-out',
  },
});

class LogDialog extends Component {
  logRef = React.createRef();

  state = {
    scrollButtonVisible: true,
  };

  componentDidMount() {
    this.scrollLogToBottom();
  }

  handleScroll = () => {
    const shouldBeVisible = this.logRef.current.scrollTop + this.logRef.current.clientHeight < this.logRef.current.scrollHeight;
    if (this.state.scrollButtonVisible !== shouldBeVisible) this.setState({
      scrollButtonVisible: shouldBeVisible,
    });
  };

  scrollLogToBottom = () => {
    if (this.logRef.current) {
      this.logRef.current.scrollTo(
        0,
        this.logRef.current.scrollHeight,
      );
    } else {
      setTimeout(this.scrollLogToBottom, 150);
    }
  };

  getTitle = () => {
    const {onClose} = this.props;

    return <DialogTitle style={{paddingRight: 8}}>
      <Grid container direction='row' justify='space-between' alignItems='center' wrap='nowrap' spacing={2}>
        <Grid item>
          {strings.log} <small>({strings.tailNLines})</small>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <ClearIcon/>
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>;
  };

  getLogLine = (line, i) => {
    const {classes} = this.props;
    return <Typography variant='h6' className={classes.logLine} noWrap key={i} >
        {line}
      </Typography>;
  };

  getLog = () => {
    const {log} = this.props;
    return <div style={{display: 'inline-block'}}>
      {log.map(this.getLogLine)}
    </div>;
  };

  getScrollButton = () => {
    const {classes} = this.props;

    if (!this.logRef.current) return;

    return <Button className={classes.scrollButton} variant='contained' color='primary'
                   disabled={!this.state.scrollButtonVisible}
                   startIcon={<KeyboardArrowDown/>} disableElevation onClick={this.scrollLogToBottom}
                   style={{
                     opacity: this.state.scrollButtonVisible ? 0.7 : 0,
                   }}>
      {strings.goToBottom}
    </Button>;
  };

  render() {
    const {open, onClose, classes} = this.props;

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth='xl' scroll='paper' className={classes.root}>
      {this.getTitle()}

      <Divider className={classes.w100}/>

      <DialogContent ref={this.logRef} onScroll={this.handleScroll}>
        {this.getLog()}
      </DialogContent>

      {this.getScrollButton()}
    </Dialog>;
  }
}

LogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  log: PropTypes.array.isRequired,
};

export default withStyles(styles)(LogDialog);

