import React, { Component, Fragment } from 'react';
import { defaultStyles } from '../util/styles';
import {
  Box,
  Button, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, Step, StepContent, StepLabel, Stepper, Typography,
  withStyles,
} from '@material-ui/core';
import { withErrorHandler } from '../util/errorHandler';
import strings from '../strings';
import { defaultBotsConfig } from '../util/constants';
import PropTypes from 'prop-types';
import { atomOneLight, CodeBlock } from 'react-code-blocks';
import Alert from '@material-ui/lab/Alert';
import api from '../util/api';


const styles = theme => ({
  ...defaultStyles(theme),
  h100: {
    height: '100%',
  },
});

class StartDefaultDialog extends Component {
  state = {
    currentBot: -1,
    currentError: false,
  };

  getStepper = () => {
    const {currentBot, currentError} = this.state;
    const {classes} = this.props;

    return <Stepper activeStep={currentBot} orientation='vertical'>
      {
        defaultBotsConfig.map((bot, i) => {
          return <Step key={i.toString()}>
            <StepLabel>{bot.script}</StepLabel>
            <StepContent>
              <Box py={1}>
                <Grid container direction='row' justify='center'>

                  <Grid item xs={12} md={6}>
                    <CodeBlock
                      text={JSON.stringify(bot.params, null, 2)}
                      language='json'
                      theme={atomOneLight}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container direction='column' justify='center' alignItems='center' spacing={1}
                          className={classes.h100}>

                      <Grid item>
                        <Box py={2}>
                          <Grid container direction='row' justify='center' spacing={1}>
                            {
                              currentError ?
                                <Grid item xs={12}>
                                  <Alert severity="error">{strings.failedToStart}</Alert>
                                </Grid>
                                :
                                <Fragment>
                                  <Grid item>
                                    <CircularProgress/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography className={classes.textCenter}>{strings.starting}</Typography>
                                  </Grid>
                                </Fragment>
                            }
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Box>
            </StepContent>
          </Step>;
        })
      }
    </Stepper>;
  };

  startCurrent = () => {
    const {currentBot} = this.state;

    const bot = defaultBotsConfig[currentBot];

    api.post('instances/new', {
      script: bot.script,
      accounts: bot.accounts,
      params: bot.params,
      paused: bot.paused,
    }, {
      onSuccess: response => this.nextBot(),
      onError: reason => {
        this.setState({currentError: true}, this.nextBotDelayed);
      },
    });
  };

  nextBotDelayed = () => {
    setTimeout(this.nextBot, 3000);
  };

  nextBot = () => {
    const {currentError, currentBot} = this.state;
    if (currentError) {
      // error showing ended
      this.setState({currentError: false}, this.nextBot);
    } else if (currentBot === defaultBotsConfig.length - 1) {
      // last one started, close dialog and show tooltip
      this.props.showSnackbar(strings.startDefaultFinished);
      this.props.onClose();
    } else {
      // increment currentBot and start it
      this.setState({currentBot: currentBot + 1}, this.startCurrent);
    }
  };

  getConfirmView = () => {
    const {onClose} = this.props;

    return <Fragment>
      <DialogContent>
        <Box pt={1} pb={2}>
          <Typography>
            {strings.startDefaultBotsDialogDescription(defaultBotsConfig.length)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {strings.cancel}
        </Button>
        <Button variant='contained' disableElevation color='primary'
                onClick={this.nextBot}>
          {strings.initStartSequence}
        </Button>
      </DialogActions>
    </Fragment>;
  };

  render() {
    const {currentBot} = this.state;
    const {onClose} = this.props;

    return <Dialog open={true} onClose={currentBot < 0 ? onClose : null} fullWidth maxWidth='sm'>
      <DialogTitle>{strings.startDefaultBotsDialogTitle}</DialogTitle>

      {
        currentBot < 0 ? this.getConfirmView() : this.getStepper()
      }
    </Dialog>;
  }
}

StartDefaultDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

export default withErrorHandler(withStyles

(
  styles,
)(
  StartDefaultDialog,
))
;