import React, { Fragment } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { defaultStyles } from '../util/styles';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  ...defaultStyles(theme),
  progress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 128,
  },
});

function Loading(props) {
  const {classes} = props;
  return <Fragment>
    <LinearProgress className={classes.progress}/>
  </Fragment>;
}

export default withRouter(withStyles(styles)(Loading));