import React from 'react';
import strings from '../strings';
import { Container, Grid, Box, withStyles, Typography } from '@material-ui/core';
import { defaultStyles } from '../util/styles';

const styles = theme => ({
  ...defaultStyles(theme),
  errorTitle: {
    fontSize: 28,
    textAlign: 'center',
  },
  errorText: {
    fontSize: 16,
    textAlign: 'center',
  },
});

export default withStyles(styles)(({errorCode, classes, ...props}) => {
  // get error message from error code (message is saved in localized strings)
  let errorMessage = strings[errorCode];

  if (errorMessage == null || errorMessage === '') {
    errorMessage = strings['errorUnknown'];
  }

  return <Container maxWidth='xs'>
    <Box py={10}>
      <Grid container direction='row' justify='center' spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h2' className={classes.errorTitle}>
            {strings.somethingWentWrong}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' className={classes.errorText}>
            {errorMessage}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Container>;
});