import React, { Component } from 'react';
import { defaultStyles } from '../util/styles';
import PropTypes from 'prop-types';
import { withErrorHandler } from '../util/errorHandler';
import api from '../util/api';
import { withRouter } from 'react-router-dom';
import strings from '../strings';
import {
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';

const styles = theme => ({
  ...defaultStyles(theme),
  select: {
    width: '100%',
    maxWidth: 256,
  },
  option: {
    fontFamily: 'Roboto Mono',
    fontSize: 12,
  },
});

class ParamsAutocomplete extends Component {
  state = {
    options: null,
    error: false,
    loadedForScript: -10,
    loading: false,
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions = () => {
    const {loading, loadedForScript} = this.state;
    if (loading || loadedForScript === this.props.script) return;

    this.setState({loading: true, loadedForScript: this.props.script});
    const url = this.props.script ? `params/autocomplete?script=${this.props.script}` : 'params/autocomplete';
    api.get(url, {}, {
      onSuccess: response => this.setState({options: response.data, error: false, loading: false}),
      onError: () => this.setState({options: null, error: true, loading: false}),
    });
  };

  onSelected = event => {
    const params = this.state.options[event.target.value];
    this.props.onOptionSelected(params);
  };

  render() {
    const {options, error} = this.state;
    const {classes} = this.props;

    if (error) return this.getError();
    this.getOptions();
    if (!options || options.length === 0) return null;


    return <Box pb={3} pt={1} className={classes.w100}>
      <FormControl className={classes.w100}>
        <InputLabel id="autocomplete-label">{strings.usePreviousParams}</InputLabel>
        <Select
          className={classes.select}
          labelId="autocomplete-label"
          value={-1}
          onChange={this.onSelected}
        >
          <MenuItem value={-1}>
            <em>{strings.none}</em>
          </MenuItem>
          {
            options.map((option, i) => (
              <MenuItem value={i} className={classes.option} key={i.toString()}>
                {JSON.stringify(option)}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>;
  }
}

ParamsAutocomplete.propTypes = {
  onOptionSelected: PropTypes.func.isRequired,
};

export default withRouter(withErrorHandler(withStyles(styles)(ParamsAutocomplete)));