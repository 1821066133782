import React from "react";
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';

function ParametersEditor(props) {
  return (
    <div style={{height: '400px'}}>
      <Editor
          width="300"
          language='json'
          value={typeof(props.placeHolder) === 'string' ? props.placeHolder : JSON.stringify(props.placeHolder, null, '\t')}
          onChange={(value) => {
            try {
              JSON.parse(value);
              props.onChange({error: false, value: value});
            } catch (e) {
              props.onChange({error: true});
            }
          }}
        />
    </div>
  );
}

ParametersEditor.propTypes = {
  placeHolder: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,

};

export default ParametersEditor;