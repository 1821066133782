import strings from '../strings';
import { amber, green, grey, red } from '@material-ui/core/colors';
import { githubRegistry, defaultRegistry, zerodaysRegistry } from './constants';

const dtFormat = 'YYYY MMM DD HH:mm:ss';

const defaultInstanceParams = {
  some_integer: 42,
};

const defaultAccountParams = {
  some_integer: 42,
};

function downloadLocalFile(fileName, content) {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function copyToClipboard(s) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(s);
  } else if (window.clipboardData) {  // IE
    window.clipboardData.setData('text', s);
  } else {  // other browsers, iOS, Mac OS
    throw DOMException('Could not copy.');
  }
}


function getStatusText(instance) {
  if (!instance.started_success) return strings.processNotStarted;
  if (!instance.pid_alive) return strings.processNotAlive;

  if (!instance.should_run && instance.pid_alive) return strings.process_should_not_run;

  if (!instance.session.params_acknowledged) return strings.waitingParamsAcknowledge;
  if (!instance.should_run) return strings.waitingToStop;

  if (instance.session.paused) return strings.sessionPaused;

  return strings.healthy;
}

function getStatusColor(instance) {
  if (!instance.pid_alive || !instance.started_success) return red[500];
  if (!instance.should_run && instance.pid_alive) return red[500];

  if (!instance.session.params_acknowledged) return amber[500];
  if (!instance.should_run) return amber[500];

  if (instance.session.paused) return grey[500];

  return green[500];
}

const cleanContainerName = (name) => {
  return name.replace(defaultRegistry, '').replace(zerodaysRegistry, '').replace(githubRegistry, '');
}

export {
  defaultInstanceParams,
  defaultAccountParams,
  copyToClipboard,
  dtFormat,
  downloadLocalFile,
  getStatusColor,
  getStatusText,
  cleanContainerName,
};