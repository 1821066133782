import React, {Component} from 'react';
import {withErrorHandler} from '../util/errorHandler';
import {withRouter} from 'react-router-dom';
import {defaultStyles} from '../util/styles';
import {
    withStyles,
    Grid,
    TableHead,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    CircularProgress,
    Button,
    Typography,
    Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions,
} from '@material-ui/core';
import axios from "axios";
import strings from "../strings";
import { Add as AddIcon } from '@material-ui/icons';
import moment from "moment";

const styles = theme => ({
    ...defaultStyles(theme),
});

class Collections extends Component {
    state = {
        data: null,
        newCollectionDialogOpen: false,
        slugValue: '',
    };

    componentDidMount() {
        this.interval = setInterval(this.loadData, 10000);
        this.loadData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    openNewCollectionDialog = () => this.setState({newCollectionDialogOpen: true});
    closeNewCollectionDialog = () => this.setState({newCollectionDialogOpen: false});

    loadData = () => {
        axios.get('https://api.nft-traits.com/v1/collections/list/synced').then(response => {
            this.setState({
                data: response.data
            })
        }).catch(reason => this.props.handleApiError(reason, this.props.history))
    }

    startAssetSync = (collection) => {
        this.setState({data: null, slugValue: ''});
        axios.post(`https://sync.nft-traits.com/api/v1/collections?access_key=PakPHo_aN62uNtqUxHPfdoyTdYYvxbWpG1zixWzD5ls`, {
            collection_name: collection.slug
        }).then(response => {
            setTimeout(this.loadData, 3000)
        }).catch(reason => this.props.handleApiError(reason, this.props.history))
    }

    render() {
        const {classes} = this.props;
        const {data} = this.state;
        if (data != null) {
            data.sort((a, b) => (a.slug.toLowerCase() > b.slug.toLowerCase()) ? 1 : -1)
        }

        return <>
            <Grid container direction='row' justifyContent='center' spacing={2}>
                <Grid item xs={12}>
                    <Box py={4}>
                        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                            <Grid item>
                                <Typography variant='h6'>
                                    {strings.collections} {data != null && data.length !== 0 ?
                                    <small>({data.length})</small> : null}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button variant='contained' color='primary' disableElevation onClick={this.openNewCollectionDialog}>
                                    <AddIcon/>
                                    <Box pl={1}/>
                                    {strings.new}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {
                    data == null ? <Grid item><CircularProgress/></Grid> : <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{strings.collection}</TableCell>
                                        <TableCell align='center'>{strings.assetSynced}</TableCell>
                                        <TableCell align="center">{strings.eventsSynced}</TableCell>
                                        <TableCell align="center">{strings.assetSyncState}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.data.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.slug}
                                            </TableCell>
                                            <TableCell align="center">{row.last_synced_assets == null ? '/' : moment(row.last_synced_assets).fromNow()}</TableCell>
                                            <TableCell align="center">{row.last_synced_events == null ? '/' : moment(row.last_synced_events).fromNow()}</TableCell>
                                            <TableCell align="center">{row.assets_sync_in_progress ? <Box pt={0.5}><CircularProgress size={20}/></Box> : <Button onClick={() => this.startAssetSync(row)} size='small' color='primary'>sync</Button>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }
            </Grid>
            <Dialog open={this.state.newCollectionDialogOpen} maxWidth='sm' fullWidth onClose={this.closeNewCollectionDialog}>
                <DialogTitle className={classes.textCenter}>{strings.addNewCollectionSlugToSync}</DialogTitle>
                <DialogContent>
                    <Grid container direction='row' justifyContent='center'>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.w100}
                                required
                                value={this.state.slugValue}
                                onChange={(e) => this.setState({slugValue: e.target.value})}
                                label={strings.slug}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={4}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeNewCollectionDialog}>
                        {strings.close}
                    </Button>
                    <Button color='primary' variant='contained' disableElevation onClick={() => {
                        this.closeNewCollectionDialog();
                        this.startAssetSync({slug: this.state.slugValue})
                    }}>
                        {strings.startSync}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    }
}

export default withErrorHandler(withRouter(withStyles(styles)(Collections)));