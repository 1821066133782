import { createMuiTheme } from '@material-ui/core/styles';
import { red, amber } from '@material-ui/core/colors';

const primary = '#2196F3';
const secondary = '#EEEEEE';

const Theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Roboto Mono',
      'monospace',
    ].join(','),
    h1: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
    },
    h6: {
      fontWeight: 300,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
    },
    overline: {
      fontWeight: 300,
    },
    subtitle1: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    subtitle2: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    body1: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    body2: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    caption: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
  },
});

const themeWarning = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: amber[200],
    },
    secondary: {
      main: amber[200],
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Roboto Mono',
      'monospace',
    ].join(','),
    h1: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
    },
    h6: {
      fontWeight: 300,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
    },
    overline: {
      fontWeight: 300,
    },
    subtitle1: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    subtitle2: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    body1: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    body2: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    caption: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
  },
});

const themeDanger = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: red[200],
    },
    secondary: {
      main: red[200],
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Roboto Mono',
      'monospace',
    ].join(','),
    h1: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
    },
    h6: {
      fontWeight: 300,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
    },
    overline: {
      fontWeight: 300,
    },
    subtitle1: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    subtitle2: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    body1: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    body2: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    caption: {
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
  },
});

export {
  Theme,
  primary,
  secondary,
  themeDanger,
  themeWarning,
};