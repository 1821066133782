import { Box, Container } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import React from 'react';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{
        minHeight: '80vh',
        paddingBottom: 24,
        overflowY: 'visible',
      }}
      {...other}
    >
      {
        value === index &&
        <Box py={4}>
          <Container maxWidth='md' disableGutters={isWidthUp('md', props.width)}>
            {children}
          </Container>
        </Box>
      }
    </div>
  );
}

export default withWidth()(TabPanel);