import routes from './routes';

const KEY_AUTH_TOKEN = 'auth_token';

function getAuthToken() {
  return window.localStorage.getItem(KEY_AUTH_TOKEN);
}

function setAuthToken(value) {
  window.localStorage.setItem(KEY_AUTH_TOKEN, value);
}

function isSignedIn() {
  return getAuthToken() != null;
}

function signOut() {
  setAuthToken(null);
  window.location.href = routes.signIn;
}

export {
  getAuthToken,
  setAuthToken,
  isSignedIn,
  signOut,
};