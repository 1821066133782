import LocalizedStrings from 'react-localization';

const languages = ['en'];
const strings = new LocalizedStrings({
  en: {
    // app name
    appName: 'botmaster',

    // errors
    somethingWentWrong: 'Something went wrong.',
    errorUnknown: 'Unknown error.',

    // misc
    ok: 'Ok',
    hour: 'hour',
    day: 'day',
    collections: 'Collections',
    collection: 'Collection',
    assetSynced: 'Asset synced',
    eventsSynced: 'Events synced',
    assetSyncState: 'Asset sync state',
    syncing: 'Syncing',
    addNewCollectionSlugToSync: 'Add new collection slug to sync',
    slug: 'Slug (case sensitive)',
    startSync: 'Start sync',
    week: 'week',
    month: 'month',
    year: 'year',
    unknown: 'unknown',
    cancel: 'Cancel',
    refresh: 'Refresh',
    log: 'Log',
    tailNLines: 'tail 300 lines',
    acknowledged: 'acknowledged',
    savePause: 'save and pause',
    saveResume: 'save and resume',
    new: 'new',
    close: 'close',
    loading: 'Loading.',
    uuid: 'UUID',
    copied: 'copied',
    copyHint: 'click to copy',
    couldNotCopy: 'copy failed',
    created: 'created',
    path: 'path',
    pid: 'PID',
    tag: 'tag',
    containerId: 'container ID',
    sessionUuid: 'session UUID',
    running: 'running',
    notRunning: 'not running',
    settings: 'Settings',
    couldNotLoadAccountData: 'Could not load account data',
    process_should_not_run: 'Process should not run but is alive.',
    sessionParamsAcknowledged: 'last session params acknowledged',
    true: 'true',
    false: 'false',
    savingSession: 'Saving session.',
    sessionSaved: 'Session saved successfully.',
    usePreviousParams: 'Use previous params',
    noSessions: 'No active sessions found.',
    noAccounts: 'No active accounts found.',
    chartInsufficientData: 'Insufficient data for this period.',
    chartDataError: 'Error while fetching data.',
    balance: 'Balance',
    currency: 'Currency',
    apiKeyProvider: 'API key provider',
    queueOpenSea: 'Queue OpenSea',
    queueLooksRare: 'Queue LooksRare',
    fatFingerBot: 'Fat Finger Bot',
    openSeaTraitQueue: 'OpenSea Trait Queue',
    available: 'Available',
    forceKill: 'Force kill',
    status: 'status',
    port: 'Port',

    // auth
    username: 'Username',
    password: 'Password',
    logIn: 'Log in',
    loginFailed: 'Login failed.',
    logOut: 'sign out',

    // home
    instances: 'Instances',
    newInstance: 'New instance',
    newAccount: 'New account',
    createAccount: 'Create account',
    accountNamePlaceholder: 'user_exchange_123',
    startBot: 'Start bot',
    script: 'Script',
    none: 'none',
    name: 'Name',
    accounts: 'Accounts',
    startPaused: 'Start paused',
    dashboard: 'Dashboard',
    redeployWorkers: 'Redeploy workers',
    redeploySuccess: 'All bots redeployed successfully.',
    redeployErrors: 'Could not deploy to servers:',
    parameters: 'Parameters',
    exchange: 'Exchange',
    owner: 'Owner',
    creatingInstance: 'Spawning new instance.',
    creatingAccount: 'Creating new account.',
    instanceRefreshFailed: 'Instance refreshing failed.',
    sessionRefreshFailed: 'Session refreshing failed.',
    startedAt: 'started at',
    paused: 'paused',
    saveAsNewSession: 'Save as new session',
    resetValues: 'Reset values',
    viewLog: 'View log',
    instanceStarted: 'New bot instance started.',
    noInstances: 'No live instances found.',
    bots: 'bots',
    sessions: 'sessions',
    sessionPaused: 'Session paused',
    account: 'account',
    processNotStarted: 'Process could not be started.',
    processNotAlive: 'Process not alive.',
    healthy: 'Process healthy',
    waitingParamsAcknowledge: 'Waiting for bot to acknowledge new parameters.',
    waitingToStop: 'Waiting for bot to stop.',
    openSession: 'Open session',
    viewInfo: 'View details',
    downloadLog: 'Download log',
    goToBottom: 'go to bottom',
    excludedAccounts: 'Excluded accounts',
    lastHour: 'Last hour',
    lastDay: 'Last day',
    lastWeek: 'Last week',
    lastMonth: 'Last month',
    stopInstance: 'Stop instance',
    instanceStopped: 'Instance stopped successfully.',
    instanceKilled: 'Instance killed successfully.',
    instanceInfoTitle: 'Instance info',
    stopBotInstance: 'Stop bot instance',
    killBotInstance: 'Kill bot instance',
    stopBotDescription: 'Bot will be let to complete its lifecycle loop and then instructed to stop itself.',
    killBotDescription: 'Bot will be force stopped. Some orders may remain open!',
    globalAccountStatsNotFound: 'Global account stats not found.',
    total: 'total',
    balancesPerCurrency: 'Balances per currency',
    balancesForAccount: 'Balances for account',
    stopAll: 'Stop all',
    killAll: 'Kill all',
    startDefaultBots: 'start default',
    startDefaultBotsDialogTitle: 'Start default bots',
    startDefaultBotsDialogDescription: n => `A total of ${n} bots will be started with their default settings. Confirm?`,
    initStartSequence: 'Initiate start sequence',
    starting: 'Starting up ...',
    failedToStart: 'Failed to start!',
    startDefaultFinished: 'Start default bots procedure finished.',
    killAllDescription: 'All bots still running will be force killed. Some orders may remain open.',
    stopAllDescription: 'All bots will be instructed to stop on their next loop.',
    killStr: 'kill',
    allInstancesKilled: 'All instances killed.',
    allInstancesStopped: 'All instances stopped.',
    stopStr: 'stop',
    fees: 'fees',
    volume: 'volume',
    pair: 'Pair',
    amount: 'Amount',
    datetime: 'Time',
    price: 'Price',
    type: 'Type',
    noOpenOrders: 'No open orders.',
    details: 'details',
  },
});

export { languages };
export default strings;