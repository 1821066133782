import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withErrorHandler} from '../util/errorHandler';
import {defaultStyles} from '../util/styles';
import {
  withStyles,
  Dialog,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Grid,
  Box,
  CircularProgress,
  TextField
} from '@material-ui/core';
import strings from '../strings';
import api from '../util/api';
import AccountsSelect from '../components/AccountsSelect';
import ParametersEditor from '../components/ParametersEditor';
import {cleanContainerName, defaultInstanceParams} from '../util/helpers';
import ParamsAutocomplete from '../components/ParamsAutocomplete';

const styles = theme => ({
  ...defaultStyles(theme),
});

class NewInstanceDialog extends Component {
  state = {
    creating: false,
    availableScripts: null,
    availableAccounts: null,
    selectedScript: -1,
    selectedAccounts: [],
    paused: false,
    port: null,

    params: defaultInstanceParams,
    paramsValid: true,
  };

  componentDidMount() {
    this.getScripts();
    this.getAccounts();
  }

  getScripts = () => {
    api.get('scripts/list', {}, {
      onSuccess: response => this.setState({
        availableScripts: response.data,
      }),
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };

  getAccounts = () => {
    api.get('accounts/active/list', {}, {
      onSuccess: response => this.setState({
        availableAccounts: response.data,
      }),
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };

  onInstanceCreated = () => {
    this.setState({
      creating: false,
      selectedScript: -1,
      selectedAccounts: [],
      params: defaultInstanceParams,
      paramsValid: true,
      paused: false,
      port: null,
    });
    this.props.onInstanceCreated();
  };

  createInstance = () => {
    const {selectedAccounts, selectedScript, params, paused, port} = this.state;

    this.setState({creating: true});

    api.post('instances/new', {
      script: selectedScript,
      accounts: selectedAccounts,
      params: params,
      paused: paused,
      port: port,
    }, {
      onSuccess: response => this.onInstanceCreated(),
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };

  onClose = () => {
    if (this.state.creating) return;
    this.props.onClose();
  };

  settingsValid = () => {
    const {selectedScript, selectedAccounts, paramsValid, port} = this.state;
    if (selectedScript === -1) return false;
    if (selectedAccounts.length === 0) return false;
    if (!paramsValid) return false;

    if (port != null && port.length > 0 && isNaN(parseInt(port))) {
      return false;
    }

    return true;
  };

  getLoading = creating => {
    const {classes} = this.props;

    return <Box py={2}>
      <Grid container direction='row' justify='center' spacing={2}>
        <Grid item>
          <Box py={3}>
            <CircularProgress/>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.textCenter}>
            {
              creating ? strings.creatingInstance : strings.loading
            }
          </Typography>
        </Grid>
      </Grid>
    </Box>;
  };

  loaded = () => {
    const {availableScripts, availableAccounts} = this.state;
    if (!availableScripts || !availableAccounts) return false;
    return true;
  };

  getScriptSelect = () => {
    const {classes} = this.props;
    const {selectedScript, availableScripts} = this.state;

    const onScriptChanged = event => {
      this.setState({
        selectedScript: event.target.value,
      });
    };

    return <Fragment>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl className={classes.w100}>
          {/*<InputLabel>{strings.script}</InputLabel>*/}
          <Select
            value={selectedScript}
            className={classes.w100}
            onChange={onScriptChanged}
          >
            <MenuItem value={-1}>{strings.none}</MenuItem>
            {
              availableScripts.map(script => <MenuItem key={`item-${script}`} value={script}>
                                                        {cleanContainerName(script)}
                                              </MenuItem>)
            }
          </Select>
        </FormControl>
      </Grid>
    </Fragment>;
  };

  getAccountsSelect = () => {
    const {selectedAccounts, availableAccounts} = this.state;
    const onAccountsChanged = newValue => this.setState({selectedAccounts: newValue});

    return <Grid item xs={12}>
      <AccountsSelect value={selectedAccounts} accounts={availableAccounts} onChange={onAccountsChanged}/>
    </Grid>;
  };

  onParamsChanged = event => {
    if (event.error) this.setState({paramsValid: false});
    else this.setState({
      params: event.value,
      paramsValid: true,
    });
  };

  onParamsSelected = params => this.setState({
    params: params,
  });

  getParamsEditor = () => {
    const {params, selectedScript} = this.state;

    return <Fragment>
      <Grid item xs={12}>
        <ParamsAutocomplete onOptionSelected={this.onParamsSelected} script={selectedScript}/>
      </Grid>
      <Grid item xs={12}>
        <ParametersEditor placeHolder={params} onChange={this.onParamsChanged}/>
      </Grid>
    </Fragment>;
  };

  onPausedChanged = event => this.setState({
    paused: event.target.checked,
  });

  getPauseInput = () => {
    const {paused} = this.state;
    return <FormControlLabel
      control={
        <Checkbox
          checked={paused}
          onChange={this.onPausedChanged}
          color='primary'
        />
      }
      label={strings.startPaused}
    />;
  };

  getPortInput = () => {
    return <Grid container direction="row" alignItems="flex-end" spacing={2}>
      <Grid item>
        <TextField
          InputLabelProps={{shrink: true}}
          label={strings.port}
          value={this.state.port}
          onChange={event => this.setState({
            port: event.target.value
          })}
        />
      </Grid>
      <Grid item xs={12}/>
      <Grid item>
        <Button color="secondary" variant="contained" disableElevation onClick={() => this.setState({port: '3333'})}>
          {strings.apiKeyProvider}
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" disableElevation onClick={() => this.setState({port: '4444'})}>
          {strings.queueOpenSea}
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" disableElevation onClick={() => this.setState({port: '6969'})}>
          {strings.queueLooksRare}
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" disableElevation onClick={() => this.setState({port: '5555'})}>
          {strings.fatFingerBot}
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" disableElevation onClick={() => this.setState({port: '6666'})}>
          {strings.openSeaTraitQueue}
        </Button>
      </Grid>
    </Grid>
  }

  getContent = () => {
    if (!this.loaded()) return this.getLoading();
    if (this.state.creating) return this.getLoading(true);

    return <Grid container direction='row'>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          {strings.script}
        </Typography>
      </Grid>
      {this.getScriptSelect()}

      <Grid item xs={12}>
        <Box pt={4}/>
      </Grid>

      <Grid item xs={12}>
        {this.getPauseInput()}
      </Grid>

      <Grid item xs={12}>
        {this.getPortInput()}
      </Grid>

      <Grid item xs={12}>
        <Box pt={4}/>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          {strings.accounts}
        </Typography>
      </Grid>
      {this.getAccountsSelect()}

      <Grid item xs={12}>
        <Box pt={4}/>
      </Grid>

      <Grid item xs={12}>
        <Box pb={1}>
          <Typography variant='subtitle1'>
            {strings.parameters}
          </Typography>
        </Box>
      </Grid>
      {this.getParamsEditor()}
    </Grid>;
  };

  render() {
    const {open} = this.props;

    return <Dialog open={open} onClose={this.onClose} maxWidth='md' fullWidth>
      <DialogTitle>
        {strings.newInstance}
      </DialogTitle>

      <DialogContent>
        {this.getContent()}
      </DialogContent>

      <DialogActions>
        <Button disabled={this.state.creating} onClick={this.onClose}>
          {strings.close}
        </Button>
        <Button variant='contained' disableElevation color='primary'
                disabled={!this.settingsValid() || this.state.creating} onClick={this.createInstance}>
          {strings.startBot}
        </Button>
      </DialogActions>
    </Dialog>;
  }
}

NewInstanceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInstanceCreated: PropTypes.func.isRequired,
};

export default withRouter(withErrorHandler(withStyles(styles)(NewInstanceDialog)));