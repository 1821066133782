import React, { Component, Fragment } from 'react';
import { defaultStyles } from '../util/styles';
import Logo from '../res/img/logo.svg';
import strings from '../strings';
import { red } from '@material-ui/core/colors';
import api from '../util/api';
import { withStyles, Grid, Box, Button, TextField, Container, CircularProgress, Typography } from '@material-ui/core';
import { setAuthToken } from '../util/auth';
import routes from '../util/routes';

const styles = theme => ({
  ...defaultStyles(theme),
  error: {
    color: red[500],
    textAlign: 'center',
    fontWeight: 600,
  },
});

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      username: '',
      password: '',
    };
  }

  onChange = key => event => {
    this.setState({
      [key]: event.target.value,
    });
  };

  onLoginSuccess = response => {
    this.setState({
      error: false,
      loading: false,
    });

    setAuthToken(response.data.token);
    window.location.href = routes.home;
  };

  onLoginError = reason => {
    console.log(reason);
    this.setState({
      error: true,
      loading: false,
    });
  };

  loginCall = () => {
    const {username, password} = this.state;
    api.post('auth/login', {
      username: username,
      password: password,
    }, {
      onSuccess: this.onLoginSuccess,
      onError: this.onLoginError,
    });
  };

  login = () => {
    this.setState({
      loading: true,
    }, this.loginCall);
  };

  onKeyPress = event => {
    const {username, password} = this.state;
    if (event.key === 'Enter' && username.length > 0 && password.length > 0) this.login();
  };

  getInputFields = () => {
    const {classes} = this.props;
    const {username, password, error} = this.state;

    return <Fragment>
      {
        error ?
          <Grid item xs={12}>
            <Typography className={classes.error}>
              {strings.loginFailed}
            </Typography>
          </Grid>
          : null
      }

      <Grid item xs={12} sm={10}>
        <TextField className={classes.w100} label={strings.username} autoFocus
                   value={username} onChange={this.onChange('username')}/>
      </Grid>

      <Grid item xs={12} sm={10}>
        <TextField className={classes.w100} label={strings.password} type='password'
                   value={password} onChange={this.onChange('password')} onKeyPress={this.onKeyPress}/>
      </Grid>

      <Grid item xs={12} sm={10}>
        <Box pt={1}>
          <Button variant='contained' color='primary' className={classes.w100} disabled={
            password.length === 0 || username.length === 0
          } onClick={this.login} onKeyPress={this.onKeyPress}>
            {strings.logIn}
          </Button>
        </Box>
      </Grid>
    </Fragment>;
  };

  render() {
    const {classes} = this.props;
    const {loading} = this.state;

    return <Box pt={8} mt={8}>
      <Container maxWidth='xs'>
        <Grid container direction='row' justify='center' spacing={3}>

          <Grid item xs={5}>
            <img src={Logo} className={classes.w100} alt='logo'/>
          </Grid>

          <Grid item xs={12}>
            <Box pb={3}/>
          </Grid>

          {
            loading ?
              <Grid item>
                <Box pt={3}>
                  <CircularProgress/>
                </Box>
              </Grid>
              :
              this.getInputFields()
          }

        </Grid>
      </Container>
    </Box>;
  }
}

export default withStyles(styles)(SignInPage);