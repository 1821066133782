import axios from 'axios';
import { apiUrl } from '../util/constants';
import { getAuthToken, isSignedIn } from './auth';

const request = method => async (
  url,
  data = {},
  {
    onSuccess,
    onError,
    headers,
    config,
  } = {
    onSuccess: () => {
    },
    onError: () => {
    },
    auth: null,
  },
) => {
  if (isSignedIn()) {
    if (!config) config = {};
    const token = await getAuthToken();
    config['params'] = {
      auth_token: token,
    };
    if (method === 'post') data['auth_token'] = token;
  }
  try {
    const response = await axios({
      method: method,
      url: apiUrl + url,
      data: data,
      ...config,
    });
    if (onSuccess !== undefined) onSuccess(response);
  } catch (error) {
    if (onError !== undefined) onError(error);
  }
};

const api = {
  get: request('get'),
  post: request('post'),
  delete: request('delete'),
  put: request('put'),
};

export default api;