import React, { Component, Fragment } from 'react';
import { defaultStyles } from '../util/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Hidden, ThemeProvider } from '@material-ui/core';
import strings from '../strings';
import { withErrorHandler } from '../util/errorHandler';
import { withRouter } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';
import NewInstanceDialog from './NewInstanceDialog';
import api from '../util/api';
import TabPanel from '../components/TabPanel';
import { Close as CloseIcon } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import {
  withStyles,
  Grid,
  Container,
  Snackbar,
  IconButton,
  Typography,
  AppBar,
  Button,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { signOut } from '../util/auth';
import { secondary, themeDanger, themeWarning } from '../util/theme';
import {
  Block as KillIcon,
} from '@material-ui/icons';
import StartDefaultDialog from './StartDefaultDialog';
import InstanceWithSessionData from "./InstanceWithSessionData";
import Collections from "./Collections";

const styles = theme => ({
  ...defaultStyles(theme),
  navbar: {
    borderRadius: 2,
    backgroundColor: '#EEEEEE',
  },
  logo: {
    height: 56,
    marginTop: 4,
  },
  navBar: {
    backgroundColor: secondary,
  },
  manageButtonContainer: {
    padding: 4,
  },
});

class Home extends Component {
  state = {
    instances: null,
    newInstanceDialogOpen: false,
    stopAllDialogOpen: false,
    killAllDialogOpen: false,
    startDefaultDialogOpen: false,
    snackbarText: '',
    snackbarOpen: false,
    tab: 0,
  };

  setTab = index => this.setState({
    tab: index,
  });

  setTabEvent = (event, index) => this.setState({
    tab: index,
  });

  componentDidMount() {
    this.interval = setInterval(this.refreshInstances, 10000);
    this.refreshInstances();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  openSnackbar = text => this.setState({
    snackbarText: text,
    snackbarOpen: true,
  });
  closeSnackbar = () => this.setState({
    snackbarOpen: false,
  });

  closeNewInstanceDialog = () => this.setState({newInstanceDialogOpen: false});
  openNewInstanceDialog = () => this.setState({newInstanceDialogOpen: true});

  openStartDefaultDialog = () => this.setState({startDefaultDialogOpen: true});
  closeStartDefaultDialog = () => this.setState({startDefaultDialogOpen: false});
  onStartDefaultDialogFinished = () => {
    this.refreshInstances();
    this.closeStartDefaultDialog();
  };

  openStopAllDialog = () => this.setState({stopAllDialogOpen: true});
  openKillAllDialog = () => this.setState({killAllDialogOpen: true});
  closeKillStopDialog = () => this.setState({killAllDialogOpen: false, stopAllDialogOpen: false}); // closes both

  onInstanceCreated = () => {
    this.closeNewInstanceDialog();
    this.openSnackbar(strings.instanceStarted);
    this.refreshInstances();
  };

  refreshInstances = () => {
    const handleRefreshError = reason => {
      if (reason.response && reason.response.status === 401) signOut();
      else this.openSnackbar(strings.instanceRefreshFailed);
    };

    api.get('instances/active', {}, {
      onSuccess: response => {
        this.setState({instances: response.data})
      },
      onError: handleRefreshError,
    });
  };

  getInstancesTitle = () => {
    const {classes} = this.props;
    const {instances} = this.state;

    return <Grid item xs={12}>
      <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
        <Grid item xs={12} sm={12} lg={4}>
          <Typography variant='h6'>
            {strings.instances} {instances != null && instances.length !== 0 ?
            <small>({instances.length})</small> : null}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Grid container direction='row' justify='flex-end' alignItems='center'>

            <Grid item xs={12} sm={6} md={3} lg={4} className={classes.manageButtonContainer}>
              <Button color='secondary' variant='contained' disableElevation className={classes.w100}
                      onClick={this.openNewInstanceDialog}>
                <AddIcon className={classes.leftButtonIcon}/>
                {strings.new}
              </Button>
            </Grid>

            {/*<Grid item xs={12} sm={6} md={3} lg={4} className={classes.manageButtonContainer}>
              <Button color='secondary' variant='contained' disableElevation className={classes.w100}
                      onClick={this.openStartDefaultDialog}>
                <PlayIcon className={classes.leftButtonIcon}/>
                {strings.startDefaultBots}
              </Button>
            </Grid>*/}

            <Hidden mdDown>
              <Grid item xs={12}/>
            </Hidden>

            <Grid item xs={12} sm={6} md={3} lg={4} className={classes.manageButtonContainer}>
              <ThemeProvider theme={themeWarning}>
                <Button color='primary' variant='contained' disableElevation className={classes.w100}
                        onClick={this.openStopAllDialog}>
                  <CloseIcon className={classes.leftButtonIcon}/>
                  {strings.stopAll}
                </Button>
              </ThemeProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={4} className={classes.manageButtonContainer}>
              <ThemeProvider theme={themeDanger}>
                <Button color='primary' variant='contained' disableElevation className={classes.w100}
                        onClick={this.openKillAllDialog}>
                  <KillIcon className={classes.leftButtonIcon}/>
                  {strings.killAll}
                </Button>
              </ThemeProvider>
            </Grid>

          </Grid>
        </Grid>

      </Grid>

      <NewInstanceDialog open={this.state.newInstanceDialogOpen} onClose={this.closeNewInstanceDialog}
                         onInstanceCreated={this.onInstanceCreated}/>
      {this.state.startDefaultDialogOpen ?
        <StartDefaultDialog onClose={this.onStartDefaultDialogFinished} showSnackbar={this.openSnackbar}/> : null}
      {this.getKillStopDialog()}
    </Grid>;
  };

  stopAll = () => {
    this.closeKillStopDialog();
    api.get(`instances/stop_all`, {}, {
      onSuccess: response => {
        this.openSnackbar(strings.allInstancesStopped);
        this.refreshInstances();
      },
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };

  killAll = () => {
    this.closeKillStopDialog();
    api.get(`instances/kill_all`, {}, {
      onSuccess: response => {
        this.openSnackbar(strings.allInstancesKilled);
        this.refreshInstances();
      },
      onError: reason => this.props.handleApiError(reason, this.props.history),
    });
  };

  getKillStopDialog = () => {
    const {killAllDialogOpen, stopAllDialogOpen} = this.state;

    if (!killAllDialogOpen && !stopAllDialogOpen) return null;

    return <Dialog open={true} onClose={this.closeKillStopDialog}>
      <DialogTitle>
        {killAllDialogOpen ? strings.killAll : strings.stopAll}?
      </DialogTitle>
      <DialogContent>
        <Box pb={3}>
          <Typography>
            {killAllDialogOpen ? strings.killAllDescription : strings.stopAllDescription}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.closeKillStopDialog}>
          {strings.cancel}
        </Button>
        <Button variant='contained' disableElevation color='primary'
                onClick={killAllDialogOpen ? this.killAll : this.stopAll}>
          {killAllDialogOpen ? strings.killStr : strings.stopStr}
        </Button>
      </DialogActions>
    </Dialog>;
  };

  getSnackbar = () => {
    const {snackbarOpen, snackbarText} = this.state;
    return <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={this.closeSnackbar}
      message={snackbarText}
      action={
        <Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={this.closeSnackbar}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </Fragment>
      }
    />;
  };

  getLoading = () => {
    return <Grid item xs={12}>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Box py={5}>
            <CircularProgress/>
          </Box>
        </Grid>
      </Grid>
    </Grid>;
  };

  getNoInstances = () => {
    const {classes} = this.props;
    return <Grid item xs={12}>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Box py={5}>
            <Typography className={classes.textCenter}>
              {strings.noInstances}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>;
  };

  getInstanceList = () => {
    const {instances} = this.state;

    if (instances == null) return this.getLoading();
    if (instances.length === 0) return this.getNoInstances();

    return instances.map(instance => (
      <Grid item xs={12} key={instance.uuid}>
        <InstanceWithSessionData instance={instance} showSnackbar={this.openSnackbar} onSave={this.refreshInstances}/>
      </Grid>
    ));
  };

  getNavBar = () => {
    const {classes} = this.props;
    const {tab} = this.state;
    return <AppBar position="static" className={classes.navbar}>
      <Container maxWidth='md' disableGutters>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.setTabEvent}
          variant='scrollable'
          className={classes.navBar}
        >
          <Tab label={strings.bots} value={0}/>
          <Tab label={strings.collections} value={1}/>
          <Tab label={strings.settings} value={2}/>
        </Tabs>
      </Container>
    </AppBar>;
  };

  getBotsPanel = () => {
    const {tab} = this.state;

    return <TabPanel value={tab} index={0}>
      <Grid container direction='row' spacing={2} alignItems='center'>
        {this.getInstancesTitle()}
        <Grid item xs={12}>
          <Box pt={3}/>
        </Grid>
        {this.getInstanceList()}
      </Grid>
    </TabPanel>;
  };

  getSettingsPanel = () => {
    const {tab} = this.state;
    return <TabPanel value={tab} index={2}>
      <Grid container direction='row' justify='center'>
        <Grid item>
          <Box py={5}>
            <Button color='primary' variant='contained' disableElevation onClick={signOut}>
              {strings.logOut}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </TabPanel>;
  };

  getCollectionsPanel = () => {
    const {tab} = this.state;
    return <TabPanel value={tab} index={1}>
      <Collections/>
    </TabPanel>;
  };

  getTabPanels = () => {
    const {tab} = this.state;

    return <SwipeableViews
      axis='x'
      index={tab}
      animateTransitions={!isWidthUp('md', this.props.width)}
      onChangeIndex={this.setTab}
    >

      <TabPanel value={tab} index={0}>
        {this.getBotsPanel()}
      </TabPanel>

      {this.getCollectionsPanel()}
      {this.getSettingsPanel()}

    </SwipeableViews>;
  };

  render() {
    return <Fragment>
      {this.getNavBar()}
      {this.getTabPanels()}
      {this.getSnackbar()}

    </Fragment>;
  }
}

export default withWidth()(withErrorHandler(withRouter(withStyles(styles)(Home))));