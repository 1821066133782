import React, { Component, Suspense } from 'react';
import { defaultStyles } from './util/styles';
import routes from './util/routes';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { parseApiError } from './util/errorHandler';
import { ErrorDialog, ErrorPage } from './errors';
import { ErrorContext } from './util/errorHandler';
import { Loading } from './components';
import SignInPage from './auth/SignInPage';
import { Home } from './home';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  ...defaultStyles(theme),
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDialogOpen: false,
      errorCode: '',
    };
  }

  showErrorDialog = errorCode => {
    this.setState({
      errorDialogOpen: true,
      errorCode: errorCode,
    });
  };

  closeDialog = () => {
    this.setState({
      errorDialogOpen: false,
      errorCode: '',
    });
  };

  handleApiError = (reason, history) => {
    // Function passed in react context, it is retrieved from context using
    // withErrorHandler higher order component. It should be called on axios
    // error. On critical error (i.e. no permission) user is redirected to
    // error page. If error is not critical just a dialog is shown.
    const redirectToErrorPage = errorCode => {
      history.push(routes.error);
      this.setState({
        errorCode: errorCode,
      });
    };

    const redirect = route => {
      history.push(route);
    };

    parseApiError(reason, redirectToErrorPage, this.showErrorDialog, redirect);
  };

  appRouter = () => {
    return <div style={{flexGrow: 1}}>
      <BrowserRouter>
        <Switch>
          {/* login page - no navbar */}
          <Route path={routes.signIn} exact component={SignInPage}/>

          <Route>
            {/* all other pages */}
            {/*<Navbar/>*/}
            <Suspense fallback={<Loading/>}>
              {/*<Container maxWidth='lg'>*/}
                <Switch>
                  {/* home */}
                  <Route path={routes.home} component={Home}/>

                  {/* errors */}
                  <Route path={routes.error404} exact>
                    <ErrorPage errorCode='error404'/>
                  </Route>
                  <Route path={routes.errorEventNotFound} exact>
                    <ErrorPage errorCode='errorEventNotFound'/>
                  </Route>
                  <Route>
                    <Redirect to={routes.error404}/>
                  </Route>
                </Switch>
              {/*</Container>*/}
            </Suspense>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>;
  };

  render() {
    const {classes} = this.props;
    return <div className={classes.root}>
      <ErrorContext.Provider value={this.handleApiError}>
        {this.appRouter()}
      </ErrorContext.Provider>
      <ErrorDialog
        open={this.state.errorDialogOpen}
        errorCode={this.state.errorCode}
        onClose={this.closeDialog}
      />
    </div>;
  }
}

export default withStyles(styles)(App);