import axios from 'axios';

const debug = false;

// API URL
// const productionApiUrl = 'https://bot.compileityourself.com/api/';
const productionApiUrl = 'https://botmaster.zerodays.dev/api/';
const debugApiUrl = 'http://localhost:8000/';

const apiUrl = debug ? debugApiUrl : productionApiUrl;

// set axios api url
axios.defaults.baseURL = apiUrl;

// default registry to remove from image tags
const defaultRegistry = 'registry.gitlab.com/zerodays/bots/';
const zerodaysRegistry = 'registry.zerodays.dev/bots/';
const githubRegistry = 'ghcr.io/zerodays/';

// current accounts config (not the best idea but fetching it each time seems bad as well)
const accounts = {
    // zan stamp
    zanStamp1: 4,
    zanStamp2: 7,
    zanStamp3: 9,
    zanStamp4: 17,

    // jan stamp
    janStamp1: 3,
    janStamp2: 6,
    janStamp3: 8,
    janStamp4: 4,
    janStamp5: 11,
    janStamp6: 12,
    janStamp7: 13,
    janStamp8: 14,
    janStamp9: 15,

    // other
    zanIg: 5,
    zanBinance1: 16,
};

// default bots
const defaultBotsConfig = [
    {
        script: 'balance_report.py',
        accounts: [
            accounts.zanBinance1,
            accounts.janStamp1,
            accounts.zanStamp1,
            accounts.janStamp2,
            accounts.zanStamp2,
            accounts.janStamp3,
            accounts.zanStamp3,
            accounts.zanStamp4,
        ],
        params: {
            period: 60,
        },
        paused: false,
    },
    {
        script: 'data_scraper.py',
        accounts: [accounts.zanIg],
        params: {
            delay: 15,
            depth: 3,
        },
        paused: false,
    },
    {
        script: 'ribic.py',
        accounts: [accounts.zanStamp1],
        params: {
            gaps: [
                [
                    0.98,
                    1,
                ],
                [
                    0.95,
                    1,
                ],
                [
                    0.9,
                    1,
                ],
                [
                    0.8,
                    1,
                ],
            ],
            base: 'btc',
            stable_coin: 'usdc',
            fast: false,
            delay: 15,
        },
        paused: false,
    },
    {
        script: 'stable_maker.py',
        accounts: [accounts.janStamp2],
        params: {
            spread: 3,
            ma_period: 1,
            stable_coin: 'eur',
            delay: 60
        },
        paused: false,
    },
    {
        script: 'stable_maker.py',
        accounts: [accounts.zanStamp2],
        params: {
            spread: 2,
            ma_period: 0.6,
            stable_coin: 'usdc',
            delay: 60
        },
        paused: false,
    },
    {
        script: 'stable_maker.py',
        accounts: [accounts.zanStamp3],
        params: {
            spread: 2,
            ma_period: 0.6,
            stable_coin: 'gbp',
            delay: 60
        },
        paused: false,
    },
    {
        script: 'ribic.py',
        accounts: [accounts.zanStamp4],
        params: {
            gaps: [
                [
                    0.98,
                    1
                ],
                [
                    0.95,
                    1
                ],
                [
                    0.9,
                    1
                ],
                [
                    0.8,
                    1
                ]
            ],
            base: 'eth',
            stable_coin: 'usdc',
            fast: false,
            delay: 15
        },
        paused: false,
    },
    {
        script: 'fx_arb.py',
        accounts: [accounts.janStamp1, accounts.zanIg],
        params: {
            spread: 0.0003,
            MA_period: 2,
            delay: 0.3,
            trailing_stop: 30,
            fees: 1,
            max_path_len: 4,
            quote: 'usd',
            base: 'eur',
            max_amount: 200
        },
        paused: false,
    },
];

export {
    debug,
    apiUrl,
    defaultBotsConfig,
    defaultRegistry,
    zerodaysRegistry,
    githubRegistry,
};