import { makeStyles } from '@material-ui/core';
import { primary } from './theme';

const defaultStyles = theme => ({
  textWhite: {
    color: 'white',
  },
  textCenter: {
    textAlign: 'center',
    width: '100%',
  },
  w100: {
    width: '100%',
  },
  h100: {
    height: '100%',
  },
  wv100: {
    minWidth: '100vw',
  },
  hv100: {
    minHeight: '100vh',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  leftButtonIcon: {
    marginRight: 6,
    transform: 'scale(0.8)',
  },
  overflowYScroll: {
    overflowY: 'scroll',
  },
  overflowXScroll: {
    overflowX: 'auto',
  },
  flexGrow: {
    flexGrow: 1,
  },
  hideScrollBar: {
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  link: {
    color: primary,
    textDecoration: 'none',
  },
  iconInButton: {
    marginRight: 12,
  },
  backgroundTransparent: {
    backgroundColor: 'transparent',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

// hook for use in functions
const useStyles = makeStyles(defaultStyles);

export { useStyles, defaultStyles };