import React, { Component } from 'react';
import { defaultStyles } from '../util/styles';
import PropTypes from 'prop-types';
import { grey, red } from '@material-ui/core/colors';
import {
  withStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import strings from '../strings';
import { copyToClipboard } from '../util/helpers';
import { primary } from '../util/theme';

const styles = theme => ({
  ...defaultStyles(theme),
  root: {
    cursor: 'pointer',
  },
  name: {
    fontSize: 12,
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'opacity 200ms ease-in-out',
  },
  content: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: [
      '-apple-system',
      'Roboto Mono',
      'monospace',
    ].join(','),
  },
  container: {
    position: 'relative',
    width: '100%',
    height: 16,
  },
});

class InfoComponent extends Component {
  state = {
    copyVisible: false,
    copyLabelText: strings.copyHint,
    hintError: false,
    hintSuccess: false,
  };

  copy = () => {
    if (this.props.disabledCopy !== undefined) return;

    try {
      copyToClipboard(this.props.textToCopy === undefined ? this.props.content : this.props.textToCopy);
      this.setState({
        copyLabelText: strings.copied,
        hintSuccess: true,
        hintError: false,
      });

    } catch (e) {
      this.setState({
        copyLabelText: strings.couldNotCopy,
        hintSuccess: false,
        hintError: true,
      });
    }

    setTimeout(() => this.setState({
      copyLabelText: strings.copyHint,
      hintSuccess: false,
      hintError: false,
    }), 1500);
  };

  getHintColor = () => {
    const {hintSuccess, hintError} = this.state;
    if (hintSuccess) return primary;
    if (hintError) return red[500];
    return grey[500];
  };

  render() {
    const {classes} = this.props;
    const {name, content, disableCopy, contentColor} = this.props;
    const {copyVisible, copyLabelText} = this.state;

    return <Grid container direction='row' justify='center' alignItems='center'
                 className={classes.root}
                 onClick={this.copy}
                 onMouseEnter={() => this.setState({copyVisible: true})}
                 onMouseLeave={() => this.setState({copyVisible: false})}>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography className={classes.name} style={{
            opacity: (!copyVisible || disableCopy) ? 1 : 0,
            color: grey[500],
            textTransform: 'uppercase',
          }}>
            {name}
          </Typography>
          <Typography className={classes.name} style={{
            opacity: (!copyVisible || disableCopy) ? 0 : 1,
            color: this.getHintColor(),
          }}>
            {copyLabelText}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.content} style={{color: contentColor == null ? grey[700] : contentColor}}>
          {content}
        </Typography>
      </Grid>
    </Grid>;
  }
}

InfoComponent.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  disableCopy: PropTypes.bool,
  textToCopy: PropTypes.string,
  contentColor: PropTypes.string,
};

export default withStyles(styles)(InfoComponent);