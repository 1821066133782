import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { defaultStyles } from '../util/styles';
import {
  withStyles,
  Grid,
  Box,
  Container,
  Button,
} from '@material-ui/core';
import Logo from '../res/img/logo.svg';
import routes from '../util/routes';
import { signOut } from '../util/auth';

const styles = theme => ({
  ...defaultStyles(theme),
  root: {
    borderRadius: 2,
    backgroundColor: '#EEEEEE',
  },
  logo: {
    height: 56,
    marginTop: 2,
  },
});

class Navbar extends Component {
  render() {
    const {classes} = this.props;
    return <Box py={3}>
      <Container maxWidth='lg'>
        <Box className={classes.root} px={4} py={1}>
          <Grid container direction='row' justify='space-between' alignItems='center'>

            <Grid item>
              <Link to={routes.home}>
                <img src={Logo} className={classes.logo} alt='logo'/>
              </Link>
            </Grid>

            <Grid item>
              <Button onClick={signOut}>
                Sign out
              </Button>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </Box>;
  }
}

export default withStyles(styles)(withRouter(Navbar));