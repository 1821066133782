const routes = {
  home: "/",

  // sign in
  signIn: "/auth/login",

  // Errors
  error: "/error",
  error404: "/error/404",
};

export default routes;
