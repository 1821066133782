import React, { Component } from 'react';
import { defaultStyles } from '../util/styles';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Checkbox,
  TableRow,
} from '@material-ui/core';
import strings from '../strings';

const styles = theme => ({
  ...defaultStyles(theme),
  headLabel: {
    fontWeight: 600,
  },
});

class AccountsSelect extends Component {
  onAllClicked = event => {
    if (event.target.checked) this.props.onChange(this.props.accounts.map(account => account.id));
    else this.props.onChange([]);
  };


  getTableHead = () => {
    const {accounts, value, classes, disabled} = this.props;
    const rowFields = [
      'name',
      'exchange',
      'owner',
    ];

    return <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={accounts.length === 0 || disabled}
            indeterminate={value.length > 0 && value.length < accounts.length}
            checked={value.length === accounts.length}
            onChange={this.onAllClicked}
            color='primary'
          />
        </TableCell>
        {rowFields.map((field) => (
          <TableCell align='left' padding='none' key={`select-${field}`}>
            <div className={classes.headLabel}>
              {strings[field]}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>;
  };

  toggleAccount = id => {
    // not very efficient
    const {value, onChange} = this.props;

    const result = [];
    let found = false;

    for (let element of value) {
      if (id === element) found = true;
      else result.push(element);
    }

    if (!found) result.push(id);
    onChange(result);
  };

  getTableBody = () => {
    const {accounts, value, disabled} = this.props;
    return <TableBody>
      {accounts.map(account => {
        const isItemSelected = value.includes(account.id);
        const labelId = `account-${account.id}`;

        return (
          <TableRow
            hover
            onClick={() => this.toggleAccount(account.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={labelId}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox
                disabled={disabled}
                checked={isItemSelected}
                inputProps={{'aria-labelledby': labelId}}
                color='primary'
              />
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none">
              {account.name}
            </TableCell>
            <TableCell padding='none' align='left'>{account.exchange}</TableCell>
            <TableCell padding='none' align='left'>{account.owner}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>;
  };

  render() {
    return <Table>
      {this.getTableHead()}
      {this.getTableBody()}
    </Table>;
  }
}

AccountsSelect.propTypes = {
  accounts: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(AccountsSelect);