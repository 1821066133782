import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  DialogActions,
  Button,
  Box,
  withStyles,
} from '@material-ui/core';
import strings from '../strings';
import { defaultStyles } from '../util/styles';

const styles = theme => ({
  ...defaultStyles(theme),
  errorTitle: {
    fontSize: 28,
    textAlign: 'center',
  },
  errorText: {
    fontSize: 16,
    textAlign: 'center',
  }
});

export default withStyles(styles)(({open, errorCode, onClose, classes, ...props}) => {
  // convert error code to human readable error message
  let message = strings[errorCode];

  if (open) console.log(errorCode);

  if (!message) {
    message = strings.errorUnknown;
  }

  // dialog shown when handleApiError is called
  return <Dialog open={open} maxWidth='xs' fullWidth>
    <Box p={2}>
      <Grid container direction='row' spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h2' className={classes.errorTitle}>
            {strings.somethingWentWrong}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' className={classes.errorText}>
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <DialogActions>
      <Button color='primary' onClick={onClose}>
        {strings.ok}
      </Button>
    </DialogActions>
  </Dialog>;
});